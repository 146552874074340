import React from "react";


const App = () => {
  const [importedText, setImportedText] = React.useState("");

  return (
    <>
      <head>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/water.css@2/out/light.min.css"
        />
      </head>
      Test-- proper site coming soon!
    </>
  );
};

export default App;
